import React, { useEffect } from "react"

import "./course.scss"
import "../../pages/index.scss"

const Course = () => {

	useEffect(() => {
		document.body.classList.add("specialBackground");
		document.body.classList.add("tanecniBackground");
		return () => {
			document.body.classList.remove("specialBackground");
			document.body.classList.remove("tanecniBackground");
		}
	}, []);
	
	return (

		<div className="tz-course">

			<div className="container mb3">

				<span className="mt1 warning" data-sal="fade" data-sal-duration="1000">registrace otevřena</span>
				<h2 className="mt1 mb3" data-sal="fade" data-sal-duration="1000">Taneční kurz: říjen - prosinec 2024</h2>

				<div className="container-50">
					<div className="tz-course__list" data-sal="fade" data-sal-duration="1000">
						<div className="tz-course__list-item">
							<div>
								<div className="tz-course__header">startujeme:</div>
								<div className="tz-course__content">16.10.2024</div>
							</div>
						</div>
						<div className="tz-course__list-item">
							<div>
								<div className="tz-course__header">středa</div>
								<div className="tz-course__content">18:45 - 20:15</div>
							</div>
						</div>
						<div className="tz-course__list-item">
							<div>
								<div className="tz-course__header">místo:</div>
								<div className="tz-course__content">Zlonín</div>
							</div>
						</div>
						<div className="tz-course__list-item">
							<div>
								<div className="tz-course__header">úroveň:</div>
								<div className="tz-course__content">začátečníci /&nbsp;mírně pokročilí</div>
							</div>
						</div>
						<div className="tz-course__list-item">
							<div>
								<div className="tz-course__header">počet lekcí:</div>
								<div className="tz-course__content">8</div>
							</div>
						</div>
						<div className="tz-course__list-item">
							<div>
								<div className="tz-course__header">cena/pár:</div>
								<div className="tz-course__content">4.200,-</div>
							</div>
						</div>
					</div>
					<div data-sal="fade" data-sal-duration="1000">
						<p className="highlighted-text" data-sal="fade" data-sal-duration="1000"><span>Naučíme vás tance, se&nbsp;kterými se můžete setkat na&nbsp;svatbách, plesech, firemních večírcích či&nbsp;reprezentativních akcích.</span></p>
						<p className="highlighted-text" data-sal="fade" data-sal-duration="1000"><span>Procvičíte si jednoduché taneční variace tanců waltz, valčík, blues, tango, foxtrot, cha-cha, rumba, jive, polka i&nbsp;základy salsy tak, aby&nbsp;vás tyto rytmy ve&nbsp;společnosti nezaskočily.</span></p>
						<p className="highlighted-text" data-sal="fade" data-sal-duration="1000"><span>Navíc při tom zažijete spoustu zábavy se&nbsp;svým partnerem a&nbsp;známými nebo&nbsp;novými sousedy.</span></p>
						<div>
							<h3>Přihlašte se:</h3>
							<ul className="tz-list">
								<li className="tz-list__item highlighted-text"><span>✅ na emailu <a className="tz-list__link" href="mailto:tanecni.zlonin@gmail.com">tanecni.zlonin@gmail.com</a></span></li>
								<li className="tz-list__item highlighted-text"><span>✅ na messengeru stránky <a className="tz-list__link" href="https://www.facebook.com/tanecni.zlonin" target="_blank" rel="noreferrer">Taneční Zlonín</a></span></li>
								<li className="tz-list__item highlighted-text"><span>✅ na telefonu/WhatsApp <a className="tz-list__link" href="tel:+420732560838">+420 732 560 838</a></span></li>
							</ul>
						</div>
					</div>
				</div>

			</div>

			<div className="tz-info">
				<div className="container tz-color-black">
					<h3 className="mt1 mb3 tz-color-black" data-sal="fade" data-sal-duration="1000">Organizační informace k&nbsp;tanečnímu kurzu</h3>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Otevření sálu a&nbsp;vstup</h4>
					<p data-sal="fade" data-sal-duration="1000">
						Vstup na sál je bočním vchodem - POZOR NE PŘES RESTAURACI! Taneční sál bude otevřen již od&nbsp;cca&nbsp;18:30. Prosíme, doražte dostatečně před začátkem, abychom mohli včas začít a&nbsp;už byli všichni připraveni na&nbsp;místě.
					</p>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Odkládání a&nbsp;přezouvání</h4>
					<p data-sal="fade" data-sal-duration="1000">
						Všechny své věci si s&nbsp;sebou noste na&nbsp;sál, kde jsou k&nbsp;dispozici židle a&nbsp;věšáky na&nbsp;odložení. Do&nbsp;čisté taneční obuvi se prosím přezouvejte ihned po&nbsp;vstupu na&nbsp;taneční sál, abychom si ho udrželi během lekce čistý a&nbsp;zajistili jeho bezvadný stav.
					</p>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Oblečení a&nbsp;obuv</h4>
					<p data-sal="fade" data-sal-duration="1000">
						Naše taneční jsou poloformální, vezměte si pohodlné společenské oblečení, ve&nbsp;kterém se budete cítit příjemně. Dámy šaty nebo sukně či společenské kalhoty s&nbsp;jakýmkoliv topem. Pánové společenské kalhoty a&nbsp;košile. Obuv: pohodlná společenská nebo přímo taneční. Vyvarujte se kovových podpatků, které by mohly poškrábat parket a&nbsp;pánové černých podrážek, které by barvily parket. Nemělo by se jednat o&nbsp;obuv, ve&nbsp;které jste přišli z&nbsp;venku.
					</p>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Občerstvení a&nbsp;restaurace</h4>
					<p data-sal="fade" data-sal-duration="1000">
						S sebou na&nbsp;taneční lekci si můžete přinést své vlastní občerstvení a&nbsp;pití. Bude zde však možnost před začátkem lekce si objednat pití v&nbsp;restauraci. V&nbsp;tomto případě objednávejte s&nbsp;dostatečným časovým předstihem, abychom mohli začít taneční lekci včas. Pokud budete chtít po&nbsp;kurzu pokračovat v&nbsp;zábavě v&nbsp;restauraci Zlonínský dvůr, doporučujeme si udělat předem rezervaci na&nbsp;<a href="https://zloninskydvur.cz" target="_blank" rel="noreferrer" className="highlight">zloninskydvur.cz</a>
					</p>
					<p data-sal="fade" data-sal-duration="1000">
						Prosíme, minimalizujte pohyb v&nbsp;restauraci před a&nbsp;během kurzu. Pro&nbsp;návštěvu toalet prosíme používejte venkovní vchod (ne přes restauraci).
					</p>

					<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Těšíme se na&nbsp;vás</h4>
					<p data-sal="fade" data-sal-duration="1000">
						Přineste si dobrou náladu a&nbsp;v&nbsp;případě jakýchkoliv dotazů nás neváhejte kontaktovat.<br />
						Irina &amp; Jarda
					</p>

				</div>
			</div>

		</div>

	)
}

export default Course