import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import "./normalize.css"

import MiniHeader from "../components/miniheader/miniheader"
import Divider from "../components/divider/divider"
import Menu from "../components/menu/menu"
import Bloglist from "../components/bloglist/bloglist"
import Footer from "../components/footer/footer"
import Course from "../components/course/course"
import Contact from "../components/contact/contact"

const Tanecni = () => {
	return (

		<Layout>
			<Seo title="Taneční kurzy pro dospělé" description="Naučíme vás tance, se kterými se můžete setkat na svatbách, plesech, firemních večírcích či reprezentativních akcích" />

			<MiniHeader text='tanecni'></MiniHeader>
			<Course></Course>
			<Contact></Contact>
			<Bloglist></Bloglist>
			<Divider></Divider>
			<Menu></Menu>
			<Footer></Footer>

		</Layout>

	)
}

export default Tanecni